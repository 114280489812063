@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat';
}
.row {
    display: flex;
    justify-content: space-between;
}
.row.center {
    align-items: center;
}
.col-1 {
    flex: 1;
}
.col-2 {
    flex: 2;
}
.text-right {
    text-align: right;
}

.orderMainContainer {
    display: flex;
    justify-content: justify;
}

.orderChildContainer {
    background-color: white;
    border-radius: 10;
    border-width: 2;
    border-color: #f2f2f4;
    display: flex;
    align-self: center;
    margin-bottom: 20;
    padding-top: 15;
    padding-bottom: 15;
}

.iconStyle {
    color: white;
    font-size: 10;
    background: rgb(76,159,111);
    background: linear-gradient(155deg, rgba(76,159,111,1) 42%, rgba(122,205,157,1) 69%);
    display: flex;
    align-self: center;
    border-radius: 10;
    padding: 20;
    margin-left: 15;
}

.orderTextStyle {
    color: black;
    font-size: 11;
    padding-left: 15;
    margin-left: 5;
  }

.orderQuantity { 
    color: #4B566B; 
    font-size: 26;
    font-weight: bold;
    padding-left: 15;
    margin-left: 5;
  }

  body::-webkit-scrollbar {
	width: 7px;
}

body::-webkit-scrollbar-thumb {
	background: #414141;
}
